import React from 'react';
import './Bird.css';
import Turnstile, { useTurnstile } from "react-turnstile";
// function verifiedCallback(token) {
//   console.log('Captcha token: ' + token);
//   // location.reload();
// }
function Bird() {
  const turnstile = useTurnstile();
  const hostname = window.location.hostname;

  if (!hostname.includes("birds.dog")) {
    return null; // or return some other component or message
  }
  return (

    <Turnstile
    sitekey="0x4AAAAAAAxZxbYxrxkqJ4jj"
    onVerify={(token) => {
      console.log("Captcha token: " + token);
      ///captcha
      fetch("/captcha/bird", {
        method: "POST",
        body: JSON.stringify({ token }),
        headers: {
          "Content-Type": "application/json",
        },
      }).finally((response) => {
        turnstile.reset();
      });
    }}
  />
  );
}

export default Bird;
