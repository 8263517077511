import React from 'react';
import './App.css';
import SliderCaptcha from '@slider-captcha/react';
function verifiedCallback(token) {
  console.log('Captcha token: ' + token);
  // location.reload();
}
function App() {
  const hostname = window.location.hostname;

  if (!hostname.includes("xblast")) {
    return null; // or return some other component or message
  }
  return (
    <div>
      <h1>Xblast Captcha</h1>
      <SliderCaptcha
        create="/captcha/create"
        verify="/captcha/verify"
        callback={verifiedCallback}
      />
    </div>
  );
}

export default App;
